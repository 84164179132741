import React, { useState, useRef } from "react";
import "./style.css";
import NewWarning from "../newWarning";
import OtherKeysDetailGln from "./OtherKeysDetailGln";

const OtherKeyResults = ({ data, keyType, code }: { data: any, keyType: string, code :string }) => {
  

  const windowSize = useRef([window.innerWidth, window.innerHeight]);


  return (

    <div className="Tabs">
      <div className=' ' style={{ width: '100%' }}>
       

      {(data.retornoMsg.showMSG) && (
          <NewWarning
            type={data.retornoMsg.vbg_status}
            showpick={data.retornoMsg.vbg_show_pic}
            title={data.retornoMsg.vbg_title}
            text={data.retornoMsg.vbg_error_message}

          ></NewWarning>
        )}

      </div>
      <>
        {data.retornoMsg.show_content && (
          <div>
            <ul className="nav">
            <li className="active" >{windowSize.current[0] > 600 ? 'Informações da Empresa' : 'Empresa'}</li>


            </ul>

            <div className="outlet">
              <OtherKeysDetailGln data={data} keyType={keyType} code={code}  />
            </div>
          </div>
        )}

      </>

    </div>
  );
};
export default OtherKeyResults;
