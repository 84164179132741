import { getConfig } from "../config/config";
import * as xlsx from "xlsx"
import * as FileSaver from "file-saver";
import { getCountryName } from './countryName';


export function exportFileTest(dataPrepared:any, filetype:string){
  const ws = xlsx.utils.json_to_sheet(dataPrepared);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(wb, { bookType: filetype=="xls"?"xls":"csv", type: "array" });
  const data = new Blob([excelBuffer]);
  FileSaver.saveAs(data, "arquivo" + "."+filetype);
}
function merge(obj1: any, obj2: any) {
  let merged = {
    ...obj1,
    ...obj2
  };
  Object.keys(obj1).filter(k => obj2.hasOwnProperty(k)).forEach((k) => {
    merged[k] = obj1[k] + "," + obj2[k]
  })
  return merged
}
export function exportFileFrontEndGTIN(data:any){
  
  let c_GTIN = data.GTIN;

  let c_Marca ="";

  var linkset={}
  var linkGroup={}
  var contGroup =0
  var grupos={}
  if(data.VBG){
    if(data.VBG.linkset){
      Object.keys(data.VBG.linkset).map((key,value) => {    
      if(Array.isArray(data.VBG.linkset[key])){
        var links={}
        contGroup++
        var nomeGrupo = "GRUPO_LINK"+contGroup+"_NOME"
        var descricaoGrupo = "GRUPO_LINK"+contGroup+"_DESCRICAO"
        var contLinks = 0
        var grupo={}

        Object.values(data.VBG.linkset[key]).map((item:any, index) => {                             
          if('href' in item){
            contLinks++
            var linkHref = "GRUPO_LINK"+contGroup+"_LINK"+contLinks+"_HREF"
            var linkTitle = "GRUPO_LINK"+contGroup+"_LINK"+contLinks+"_TITLE"
            var linkType = "GRUPO_LINK"+contGroup+"_LINK"+contLinks+"_TYPE"
            var linkLanguage = "GRUPO_LINK"+contGroup+"_LINK"+contLinks+"_LANGUAGE"
            var link={
              [linkHref]:item.href,
              [linkTitle]:item.title,
              [linkType]:item.type,
              [linkLanguage]:item.hreflang[0]              
            }
            links = merge(links,link)
          }else{
            grupo={
              [nomeGrupo]:data.VBG.linkset[key][index].name,
              [descricaoGrupo]:data.VBG.linkset[key][index].definition
            }
          }
      })
        var grupoFinal = merge(grupo,links)
        grupos = merge(grupos,grupoFinal)
      }
    })
    }
  }

  if(!!data.CNP && !!data.CNP.product && !!data.CNP.product.brandNameInformationLang && data.CNP.product.brandNameInformationLang.length>0){
    data.CNP.product.brandNameInformationLang.map((item:any) =>{
      c_Marca += "("+item.languageCode+") "+ item.brandName+" "
     })
  }else{
    if(!!data.VBG && !!data.VBG.brandName && data.VBG.brandName.length>0){
       data.VBG.brandName.map((item:any) =>{
        c_Marca += "("+item.language+") "+ item.value+" "
       })
    }
  }

  let c_Descricao="";
 
  if(!!data.CNP && !!data.CNP.product && !!data.CNP.product.tradeItemDescriptionInformationLang && data.CNP.product.tradeItemDescriptionInformationLang.length>0){
    
    data.CNP.product.tradeItemDescriptionInformationLang.map((item:any) =>{
      c_Descricao += "("+item.languageCode+") "+ item.tradeItemDescription+" "
     })


  }else{
    
    if(!!data.VBG && !!data.VBG.productDescription && data.VBG.productDescription.length>0){
      data.VBG.productDescription.map((item:any) =>{
        c_Descricao += "("+item.language+") "+ item.value+" "
      })
    }
  }


  let c_URLImagem="";
  if(!!data.CNP && !!data.CNP.product && !!data.CNP.product.referencedFileInformations && data.CNP.product.referencedFileInformations.length>0){
   
    data.CNP.product.referencedFileInformations.map((item:any) =>{
      c_URLImagem += "("+item.languageCode+") "+ item.uniformResourceIdentifier+" "
     })


  }
  else{
   
    if(!!data.VBG && !!data.VBG.productImageUrl && data.VBG.productImageUrl.length>0){
      data.VBG.productImageUrl.map((item:any) =>{
        c_URLImagem += "("+item.language+") "+ item.value+" "
      })
   }
  }
  let c_ClassificacaoGlobal ;
  if(!!data.CNP && !!data.CNP.product)
    c_ClassificacaoGlobal = data.CNP.product.tradeItemClassification.gpcCategoryCode;
  if(!!!c_ClassificacaoGlobal && !!data.VBG){
    c_ClassificacaoGlobal = data.VBG.gpcCategoryCode;
  }

  let c_ConteudoLiquido ;
  if(!!data.CNP && !!data.CNP.product)
    c_ConteudoLiquido = data.CNP.product.tradeItemMeasurements.netContent.originalValue+ " "+data.CNP.product.tradeItemMeasurements.netContent.measurementUnitCode;
  if(!!data.VBG && !!!c_ConteudoLiquido && !!data.VBG.netContent){
     c_ConteudoLiquido = data.VBG.netContent[0].value+' '+data.VBG.netContent[0].unitCode;
  }
  let countriesComplete: { sigla: any; name: string; }[] =[]
  if (data.VBG && data.VBG.productImageUrl && data.VBG.productImageUrl.length > 0 && data.VBG.countryOfSaleCode){
    data.VBG.countryOfSaleCode.map((item:any) =>{
          let nomePais = getCountryName(
              item.alpha2
          )
          let country={
              sigla:item.alpha2,
              name:nomePais
          }
          countriesComplete.push(country)

      })
  }

  let c_PaisVenda =""
  countriesComplete.map((item: any, index: number) => (                                                
    c_PaisVenda +="("+item.sigla+") "+item.name+", "                                  
  ))
  

  let c_AtualizadoEm =""

  if(!!data.VBG && !!data.VBG.dateUpdated){
    c_AtualizadoEm= data.VBG.dateUpdated;
  }
  let c_CodigoErro;
  if(!!data.VBG && !!data.VBG.validationErrors){
    c_CodigoErro = data.VBG.validationErrors[0].errors[0].errorCode
  }
   
  let c_gtinOrigem;
  let c_qtdGtinOrigem;

  if(!!data.CNP && !!data.CNP.product && !!data.CNP.product.childTradeItems && data.CNP.product.childTradeItems.length>0){
    c_gtinOrigem = data.CNP.product.childTradeItems[0].gtin;
    c_qtdGtinOrigem = data.CNP.product.childTradeItems[0].quantityOfNextLowerLevelTradeItem;  
  }

  let c_NCM;
  let c_CEST;
  
  if(!!data.CNP && !!data.CNP.product && !!data.CNP.product.tradeItemClassification.additionalTradeItemClassifications && data.CNP.product.tradeItemClassification.additionalTradeItemClassifications.length>0){
    data.CNP.product.tradeItemClassification.additionalTradeItemClassifications.forEach((element: {
      additionalTradeItemClassificationCodeValue: any; additionalTradeItemClassificationSystemCode: string; 
}) => {
      if(element.additionalTradeItemClassificationSystemCode=="NCM"){
        c_NCM = element.additionalTradeItemClassificationCodeValue;
      }
      if(element.additionalTradeItemClassificationSystemCode=="CEST"){
        c_CEST = element.additionalTradeItemClassificationCodeValue;    
      }
    });
    
  }

  let c_pesoBruto 
  if(!!data.CNP && !!data.CNP.product )
    c_pesoBruto = data.CNP.product.tradeItemWeight.grossWeight.value+" "+data.CNP.product.tradeItemWeight.grossWeight.measurementUnitCode;

  let c_SincronizadoCCG
  if(!!data.CNP && !!data.CNP.product )
    c_SincronizadoCCG = data.CNP.product.syncInformationCCG?"Sim":"Não";

  let c_gs1nomeEmpresa
  if(!!data.VBG && !!data.VBG.gs1Licence && !!data.VBG.gs1Licence.licenseeName){
    c_gs1nomeEmpresa = data.VBG.gs1Licence.licenseeName;
  }
  
  let c_Website ="";
  
  if(!!data.VBG && !!data.VBG.gs1Licence && !!data.VBG.gs1Licence.contactPoint){
    data.VBG.gs1Licence.contactPoint.map((txt: { website: string; }) =>
      c_Website+=txt.website+", "
    );
  }
  
  let c_tipolicensa = "";
  let c_NroGlobalLocalizacao = "";
  let c_gs1licenciante = "";
  if(!!data.VBG && !!data.VBG.gs1Licence && !!data.VBG.gs1Licence.licenceType){
      c_tipolicensa = data.VBG.gs1Licence.licenceType;
      
  }
  if(!!data.VBG && !!data.VBG.gs1Licence && !!data.VBG.gs1Licence.licenseeGLN){
    c_NroGlobalLocalizacao = data.VBG.gs1Licence.licenseeGLN;
  }
  if(!!data.VBG && !!data.VBG.gs1Licence && !!data.VBG.gs1Licence.licensingMO && !!data.VBG.gs1Licence.licensingMO.moName){
    c_gs1licenciante = data.VBG.gs1Licence.licensingMO.moName;
  }

  let c_Endereco = "";
  if(!!data.VBG && !!data.VBG.gs1Licence && !!data.VBG.gs1Licence.address){
    if(!!data.VBG.gs1Licence.address.streetAddress)
      c_Endereco += data.VBG.gs1Licence.address.streetAddress.value;
    if(!!data.VBG.gs1Licence.address.streetAddressLine2)
      c_Endereco += data.VBG.gs1Licence.address.streetAddressLine2.value;
    if(!!data.VBG.gs1Licence.address.addressLocality)
      c_Endereco += data.VBG.gs1Licence.address.addressLocality.value;
    if(!!data.VBG.gs1Licence.address.addressRegion)
      c_Endereco += data.VBG.gs1Licence.address.addressRegion.value;
    if(!!data.VBG.gs1Licence.address.postalCode)
      c_Endereco += data.VBG.gs1Licence.address.postalCode;
    if(!!data.VBG.gs1Licence.address.countryCode)
      c_Endereco += data.VBG.gs1Licence.address.countryCode;
  }


  let c_Erro =""
  if(data.mensagens.geral != null && data.mensagens.geral.length>0){
    let tituloErro  =(data.mensagens.geral[0].titulo!=null && data.mensagens.geral[0].titulo!=undefined)?data.mensagens.geral[0].titulo:""
    let msgErro  =(data.mensagens.geral[0].mensagem!=null && data.mensagens.geral[0].mensagem!=undefined)?data.mensagens.geral[0].mensagem:""
    c_Erro = msgErro +" " +tituloErro
  }
  

  let item={
    'GTIN': c_GTIN, 
    'Marca': c_Marca,
    'Descrição': c_Descricao,
    'URL da imagem': c_URLImagem,
    'Classificação global': c_ClassificacaoGlobal,
    'Conteúdo líquido': c_ConteudoLiquido,
    'País de venda': c_PaisVenda,
    'Atualizado em': c_AtualizadoEm,
    'Código de erro': c_CodigoErro,
    'GTIN origem': c_gtinOrigem,
    'Qtd. GTIN origem': c_qtdGtinOrigem,
    'NCM': c_NCM,
    'CEST': c_CEST,
    'Peso bruto': c_pesoBruto,
    'Sincronizado CCG': c_SincronizadoCCG,
    'Nome da empresa': c_gs1nomeEmpresa,
    'Endereco': c_Endereco,
    'Site': c_Website,
    'Tipo de Prefixo': c_tipolicensa,
    'Número Global de Localização (GLN)': c_NroGlobalLocalizacao,
    'Organização Membro GS1': c_gs1licenciante,
    'Erro': c_Erro    
  }
  item = merge(item, grupos)
  return item;
}