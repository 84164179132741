import React, { useContext, useRef, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import "./style.css"
import "../../../global.style.css"


import ExportDetail from '../../../../components/ExportDetail';
import Warning from '../../../../components/Warning';
import { LoginContext } from '../../../../contexts/login_context';
import { dismissToast, showError, showWait } from '../../../../services/toast';
import { useSelector } from '../../../../native-state.dev';
import { getOtherKeys } from '../../../../services/getOtherKeys';
import OtherKeyResults from '../../../../components/OtherKeysResult';
import { createErrorMsg, processOtherKeyData } from '../../../../services/other_keys_process';
import { exportFileFrontEndOtherKey, exportFileFrontEndOtherKeyError } from '../../../../services/exportFileFrontEndOtherKeys';
import { KeyTypesVerified } from '../../../../helpers/keyTypes';



const SearchOneOtherKeys = () => {
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);
  const [view, setView] = useState({ error: false, content: false });
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { jwt } = useContext(LoginContext);
  const [code, setCode] = useState("");
  const [keyType, setKeyType] = useState<any>(null);
  const [isFocused, setFocused] = useState(false)
  const myRef: any = useRef();

  let navigate = useNavigate();
  let location = useLocation();
 

  const [data, setData] = useState<any>();
  const [dataPrepared, setdataPrepared] = useState<any>();

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    send()
  }


  async function send() {
    searchCode();

  }

  const validateOtherKeys = () => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
    setView({ error: false, content: false });

    const select = document.getElementById('selectId') as HTMLSelectElement;
    const input = document.getElementById('inputId') as HTMLInputElement;
    const currentValue = select.value;
    const currentInputValue = input.value.trim();

    if (currentInputValue === '') {
        error = true;
        titleMessage = 'Nenhum código foi digitado no campo de busca';
        message = 'Por favor, informe ao menos um código para consulta.';
    } else if (currentValue === '') {
        error = true;
        titleMessage = 'Nenhuma chave GS1 foi selecionada';
        message = 'Por favor, verifique se há alguma chave GS1 selecionada.';
    }
    // } else if (["SSCC", "GSRN", "GSIN"].includes(currentValue)) {
    //     if (!/^[0-9]+$/.test(currentInputValue)) {
    //         error = true;
    //         titleMessage = `O código ${currentValue} deve conter apenas caracteres numéricos.`;
    //         message = 'Por favor, verifique se há erros de digitação.';
    //     } else if (currentInputValue.length !== (currentValue === "GSIN" ? 17 : 18)) {
    //         error = true;
    //         titleMessage = `O número do código ${currentValue} deve ter ${currentValue === "GSIN" ? 17 : 18} dígitos e ser válido.`;
    //         message = `O código ${currentValue} deve ter exatamente ${currentValue === "GSIN" ? 17 : 18} dígitos.`;
    //     } else if ([9, 10, 11].includes(currentInputValue.length)) {
    //         error = true;
    //         titleMessage = `O número informado não é um código ${currentValue} válido.`;
    //         message = 'Por favor, verifique erros de digitação e contate a GS1 Brasil se precisar de ajuda.';
    //     } else if (currentInputValue.startsWith('000')) {
    //         error = true;
    //         titleMessage = `O número informado não é um código ${currentValue} válido.`;
    //         message = 'Por favor, verifique se há erros de digitação.';
    //     } else {
    //         const positionNumber = currentValue === "GSIN" ? 17 : 18;
    //         const positionValue = Array(positionNumber - 1).fill(0).map((_, i) => (i % 2 === 0 ? 3 : 1));
    //         const otherKeysPositions = currentInputValue.slice(0, positionNumber - 1).split("").map(Number);

    //         let total = otherKeysPositions.reduce((acc, digit, i) => acc + digit * positionValue[i], 0);
    //         const checkDigit = (Math.ceil(total / 10) * 10) - total;

    //         if (checkDigit !== parseInt(currentInputValue[positionNumber - 1], 10)) {
    //             error = true;
    //             titleMessage = `Dígito verificador incorreto. Você quis dizer ${currentInputValue.slice(0, positionNumber - 1)}${checkDigit}?`;
    //             message = 'Por favor, verifique se o dígito verificador está correto.';
    //         } else {
    //             titleMessage = `${currentValue} válido.`;
    //         }
    //     }
    // }

    return { error, titleMessage, message };
};




  const searchCode = async () => {

    let valid = validateOtherKeys();

    if (!valid.error) {
      let toastId = showWait('Aguarde, enquanto realizamos sua pesquisa.');
      setIsDisabled(true);

      const response: any = await getOtherKeys([[code], keyType.typeKey], jwt);
      dismissToast(toastId);
      setIsDisabled(false);

      if (response.status === 200) {


        if (response.data != null && response.data.length > 0) {
          setView({ error: false, content: true });
          
          setState({searchDone:true});


          if (myRef && myRef.current)
            myRef.current.scrollIntoView();
            let errorMsg = {}
          if(!!response.data[0].validationErrors){
            errorMsg = createErrorMsg(response.data[0])
            let auxData:any={}
            auxData.retornoMsg = errorMsg;
            setData(auxData)
            setdataPrepared(exportFileFrontEndOtherKeyError({gln:code}));

          }else{
            let item = processOtherKeyData(response.data[0],keyType.typeKey)
            setData(item);
            setdataPrepared(exportFileFrontEndOtherKey(item, keyType.typeKey));

            if(response.data.length>1){
              errorMsg =  createErrorMsg(response.data[1])
              data.retornoMsg = errorMsg;
              setData(data)
              // setdataPrepared(data);

            }
            

            
          }



          console.log(data);
        }
        else {
          setView({ error: true, content: false });
          setErrorTitle(`O código fornecido não foi encontrado.`);
        }

      }
      else {

        if (response.code === 401) {
          navigate("/" + location.search);
        }

        showError(response.msg);
        setView({ error: false, content: false });
      }
    }
    else {
      setView({ error: true, content: false });
      setErrorTitle(valid.titleMessage);
      setErrorMessage(valid.message);
    }


  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value; // A string selecionada (typeKey)
    const selectedKeyType = KeyTypesVerified[selectedValue]; // Obtém o objeto completo baseado no typeKey

    // Apenas se o objeto existir no KeyTypesVerified
    if (selectedKeyType) {
      setKeyType(selectedKeyType); // Armazena o objeto completo no estado
    }
  }




  return (

    <>


      <div className='bg-color-ui-1  ' >

        <div className='container'>

          <div className='row customWidth bg-color-white  px-4 rounded-lg' >

            <div className='col-12 col-md-12 mb-5' >
              <form onSubmit={onFormSubmit}>
                <div id='custom-responsi'>
                    <div  className=" custom-select1 form-group mb-0">
                                    <select
                                        style={{height:'46px', padding:'0px', paddingLeft:'10px'}}
                                        className="form-control"
                                        id='selectId'
                                        onChange={handleSelectChange}
                                        defaultValue={''}
                                    >
                                         <option disabled value={''}>Selecione uma chave GS1</option>
                                           {/* Gera os options dinamicamente a partir do KeyTypesVerified */}
                                           {Object.keys(KeyTypesVerified).map((key) => (
                                                              <option key={key} value={key}>
                                                                {KeyTypesVerified[key].description}
                                                              </option>
                                                            ))}
                                    </select>
                        </div>
                        
                    <div className='custom-container-input' style={{display:'flex'}}>
                      <div className='custom-border5'
                      >
                          <label
                          
                          className='container_my_input_custom custom-height-container-input'>
                          <BsSearch />
                            <input
                            id='inputId'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            placeholder="Digite o número do código selecionado"
                            onChange={({ target }) => setCode(target.value)}
                            value={code}
                            className="especial_input"
                            style={{outline:'none', border: 'none'}}
                            disabled={isDisabled}
                            />

                          </label>
                      </div>
                      <div>
                        <button
                            type="submit"
                            className="custom-button-especial"
                            disabled={isDisabled}
                        >
                            Buscar
                        </button>
                      </div>
                    </div>

                </div>
              </form>
            </div>


            <div ref={myRef}></div>



          </div>

        </div>

      </div>
      <div className='col-12' style={{ width: '100%', overflow: 'hidden', padding: '0' }}>

        {view.error && (

          <div className='row'>

            <Warning
              type='error'
              showpick={false}
              title={errorTitle}
              text={errorMessage}
            ></Warning>

          </div>

        )}

        {view.content && (

          <>
            
            <OtherKeyResults data={data} keyType={keyType.typeKey} code={code} />


          </>

        )}
      </div>

      {view.content && (<ExportDetail dataPrepared={dataPrepared} itemType={'OTHER_KEY'} />)}
      
    </>

  );


};

export default SearchOneOtherKeys;



