export type KeyTypeVerified = {
  size: number;
  typeKey: string;
  variableLength: boolean; 
  compareCode: (value: string) => boolean;
  description: string;
};

const defaultValidateKey = function (this: KeyTypeVerified, value: string): boolean {
  return value.length === this.size && /^[0-9]+$/.test(value); 
};

const variableLengthValidateKey = function (this: KeyTypeVerified, value: string): boolean {
  return value.length >= this.size && /^[0-9A-Za-z%]+$/.test(value);
};

export const KeyTypesVerified: { [key: string]: KeyTypeVerified } = {
  SSCC: {
    size: 18,
    typeKey: "SSCC",
    variableLength: false,
    compareCode: defaultValidateKey,
    description : "SSCC - Identificação de Unidades Logísticas"
  },
  GRAI: {
    size: 14,
    typeKey: "GRAI",
    variableLength: false,
    compareCode: defaultValidateKey,
    description : "GRAI - Identificador Global de Ativo Retornável"
  },
  GIAI: {
    size: 13,
    typeKey: "GIAI",
    variableLength: true,
    compareCode: variableLengthValidateKey,
    description : "GIAI - Identificador Global de Ativo Individual"
  },
  GSIN: {
    size: 17,
    typeKey: "GSIN",
    variableLength: false,
    compareCode: defaultValidateKey,
    description : "GSIN - Número Global de Identificação de Carga"
  },
  GINC: {
    size: 13,
    typeKey: "GINC",
    variableLength: true, 
    compareCode: variableLengthValidateKey,
    description : "GINC - Número Global de Identificação de Consignação"
  },
  GSRN: {
    size: 18,
    typeKey: "GSRN",
    variableLength: false,
    compareCode: defaultValidateKey,
    description : "GSRN - Número Global da Relação de Serviço"
  },
  GDTI: {
    size: 13,
    typeKey: "GDTI",
    variableLength: true, 
    compareCode: variableLengthValidateKey,
    description : "GDTI - Identificador Global do Tipo de Documento"
  },
  GCN: {
      size: 13,
      typeKey: "GCN",
      variableLength: true, 
      compareCode: variableLengthValidateKey,
      description : "GCN - Número de Cupom Global"
  },
  CPID: {
      size: 30,
      typeKey: "CPID",
      variableLength: true, 
      compareCode: variableLengthValidateKey,
      description : "CPID - Identificador de Componente/Peça"
  },
  GMN: {
      size: 25,
      typeKey: "GMN",
      variableLength: true, 
      compareCode: variableLengthValidateKey,
      description : "GMN - Número do Modelo Global"
  },
};

export const validationOtherKeys = (typesKey: string, otherKeys: string): any => {
  let message: string = '';
  let titleMessage: string = '';
  let error: boolean = false;

  const keyDetails = KeyTypesVerified[typesKey];

  if (!keyDetails) {
    return {
      error: true,
      titleMessage: 'Tipo de chave não suportado.',
      message: ''
    };
  }

  // Verificar se a chave tem o comprimento necessário (ou mais, se for variável)
  if (keyDetails.compareCode.call(keyDetails, otherKeys)) {
    // Se a chave passar na validação básica de tamanho e formato, prosseguir com a verificação do dígito verificador
    const position_number = keyDetails.size - 1;
    let position_value = Array(position_number).fill(0).map((_, i) => (i % 2 === 0 ? 3 : 1));
    let otherKeys_total = 0;
    let otherKeys_positions = otherKeys.split("").slice(0, position_number); // Excluir o último dígito

    // Calcular o total de multiplicação
    for (let i = 0; i < position_number; i++) {
      otherKeys_total += position_value[i] * parseInt(otherKeys_positions[i], 10);
    }

    let multiple_10 = Math.ceil(otherKeys_total / 10) * 10;
    let check_digit = multiple_10 - otherKeys_total;

    // Verificar o dígito verificador
    if (check_digit !== parseInt(otherKeys.substring(position_number), 10)) {
      error = true;
      titleMessage =` Dígito verificador incorreto. Você quis dizer ${otherKeys.slice(0, position_number)}${check_digit}?`;
      message = '';
    } else {
      titleMessage =`${typesKey} válido.`
    }
  } else {
    // Se falhar a validação de tamanho/formato
    error = true;
    titleMessage = `O número do código ${typesKey} deve conter pelo menos ${keyDetails.size} dígitos e ser válido.`
    message = 'Por favor, verifique o código e tente novamente.';
  }

  return { error, titleMessage, message };
};